import LogoIcon from "./LogoIcon";
import SubscriptionForm from "./SubscriptionForm"; // assuming OverlayContent is in the same directory
import { useState } from "react";
import SuccessIcon from "./SuccessIcon";

function App() {
  const [overlay, setOverlay] = useState(false);

  return (
    <div
      className={`bg-cover bg-center bg-no-repeat h-screen w-full fixed overflow-auto`}
      style={{ backgroundImage: `url('/images/background.jpg')` }}
    >
      <div className="flex flex-col items-center mt-9 relative z-30">
        <LogoIcon withText />
      </div>
      {overlay ? (
        <div className="absolute inset-0 bg-backdrop backdrop-blur-[22.5px] animate-appearNotification">
          <div className="flex flex-col items-center justify-center h-full p-5 relative">
            <div
              className="animate-appear absolute z-[5] flex items-center pt-6 px-6 pb-6 mobile:w-[20rem] w-[30rem] h-[8rem] rounded-2xl backdrop-blur-[33.5px] bg-successNotification2"
            >
              <div className="flex flex-row items-center">
                <div className="mt-7">
                  <SuccessIcon />
                </div>
                <div className="ml-4">
                  <p className="text-xl text-gray-50 font-semibold">
                    Thank you for subscribing!
                  </p>
                  <p className="text-[13px] text-gray-100 font-normal mt-3">
                    We will inform you when we make a next step 🚀
                  </p>
                </div>
              </div>
            </div>
            <div
              className="animate-appearNotification absolute z-[4] mt-7 flex items-center pt-6 px-8 pb-6 mobile:w-[17rem] w-[27rem] h-[8rem] rounded-2xl backdrop-blur-[33.5px] bg-successNotification"
            ></div>
            <div
              className="animate-appearNotification absolute z-[3] mt-14 flex items-center pt-6 px-8 pb-6 mobile:w-[14rem] w-[24rem] h-[8rem] rounded-2xl backdrop-blur-[33.5px] bg-successNotification"
            ></div>
            <div className="bg-yellow w-[150px] h-[100px]"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center w-full relative h-[60%] p-5">
            <div
              className="pt-7 px-6 pb-8 mobile:w-[20rem] w-[29rem] h-[8rem] rounded-2xl backdrop-blur-[33.5px] bg-successNotification absolute z-[5] animate-appearForm"
            >
              <div className="flex flex-row mobile:mb-2 mb-5 justify-between items-center">
                <div className="flex flex-row items-center">
                  <LogoIcon width="24.609px" height="27.416px" />
                  <h3 className="ml-3 text-button-bg text-xl font-medium leading-normal tracking-[-0.03rem]">
                    Coming soon...
                  </h3>
                </div>
                <span className="text-grey-500 text-[12px] font-normal tracking-[-0.04375rem] self-start">
                  now
                </span>
              </div>
              <p className="text-[14px] text-gray-50 font-medium">
                Be the first to get notified when we're open for business!
              </p>
            </div>
            <div
              className="pt-5 px-6 pb-10 mobile:w-[17rem] w-[26rem] h-[8rem] rounded-2xl backdrop-blur-[33.5px] bg-successNotification absolute z-[4] mt-7 animate-appearForm"
            ></div>
            <div
              className="pt-5 px-6 pb-10 mobile:w-[14rem] w-[23rem] h-[8rem] rounded-2xl backdrop-blur-[33.5px] bg-successNotification absolute z-[3] mt-14 animate-appearForm"
            ></div>
            <div className="absolute mt-[360px]">
              <SubscriptionForm setOverlay={setOverlay} />
            </div>
          </div>
        </>
      )}
      <footer className="absolute bottom-0 w-full text-gray-100 text-xs leading-normal font-light mb-8 mobile:mb-20 font-poppins text-center z-30">
        © blackfield labs 2023. All rights reserved.
      </footer>
    </div>
  );
}

export default App;
