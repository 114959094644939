import { FC, useState } from "react";
import axios from "axios";

interface SubscriptionFormProps {
  setOverlay: (value: boolean) => void;
}

const SubscriptionForm: FC<SubscriptionFormProps> = ({ setOverlay }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubscribe = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }
    axios
      .post("https://nh3tl6q3e44lxsd4r5yxikbhma0maznk.lambda-url.eu-central-1.on.aws", { email })
      .then((response) => {
        // Assuming server responds with success status for valid request
        setOverlay(true);
      })
      .catch((error) => {
        // Handle error situation
        setError("Something went wrong. Please try again later.");
      });
  };
  return (
    <div>
      <div className="flex flex-col items-center">
        <form onSubmit={handleSubscribe}>
          <input
            type="text"
            className="animate-appearForm w-[20rem] h-[3rem] px-5 py-4 rounded-lg bg-inputField backdrop-blur-[25px] text-gray-100 placeholder:text-grey-100 text-[14px] font-light focus:outline-none shadow-custom-box"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          {error && <p className="text-red-500 mt-2 text-[14px]">{error}</p>}

          <button
            type="submit"
            className="animate-appearForm w-[20rem] h-[3rem] flex items-center justify-center px-4 rounded-lg bg-button-bg mt-3 hover:bg-button-hover text-[14px] font-medium text-custom-black cursor-pointer shadow-custom-box"
          >
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
};
export default SubscriptionForm;
